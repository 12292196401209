<template>
  <div class="home_view">
    <SocialMedia></SocialMedia>
    <Navbar></Navbar>

    <spinner class="spinner" v-if="is_loading"></spinner>

    <div v-else>
      <div class="ar" v-if="getLang === 'ar'">
        <div class="slider">
          <div class="slider__owl-main">
            <div class="slider__owl-main__slide-items">
              <div v-if="slider_1" class="slider__owl-main__slide-items__item animate__animated animate__fadeIn">
                <div class="slider__owl-main__slide-items__item__overlay"></div>
                <img class="slider__owl-main__slide-items__item__bg" :src="sliders[0].image_ar">
                <div class="slider__owl-main__slide-items__item__content">
                  <div class="slider__owl-main__slide-items__item__content__main-title">
                    {{ sliders[0].title_ar }}
                  </div>
                  <div class="slider__owl-main__slide-items__item__content__sub-title">
                    {{ sliders[0].description_ar }}
                  </div>
                  <div class="slider__owl-main__slide-items__item__content__btn btn--animated mt-4 mr-3">
                    <router-link to="">تعرف علي المزيد</router-link>
                  </div>
                </div>
              </div>
              <div v-if="slider_2" class="slider__owl-main__slide-items__item animate__animated animate__fadeIn">
                <div class="slider__owl-main__slide-items__item__overlay"></div>
                <img class="slider__owl-main__slide-items__item__bg" :src="sliders[1].image_ar">
                <div class="slider__owl-main__slide-items__item__content">
                  <div class="slider__owl-main__slide-items__item__content__main-title">
                    {{ sliders[1].title_ar }}
                  </div>
                  <div class="slider__owl-main__slide-items__item__content__sub-title">
                    {{ sliders[1].description_ar }}
                  </div>
                  <div class="slider__owl-main__slide-items__item__content__btn btn--animated mt-4 mr-3">
                    <router-link to="">تعرف علي المزيد</router-link>
                  </div>
                </div>
              </div>
              <div v-if="slider_3" class="slider__owl-main__slide-items__item animate__animated animate__fadeIn">
                <div class="slider__owl-main__slide-items__item__overlay"></div>
                <img class="slider__owl-main__slide-items__item__bg" :src="sliders[2].image_ar">
                <div class="slider__owl-main__slide-items__item__content">
                  <div class="slider__owl-main__slide-items__item__content__main-title">
                    {{ sliders[2].title_ar }}
                  </div>
                  <div class="slider__owl-main__slide-items__item__content__sub-title">
                    {{ sliders[2].description_ar }}
                  </div>
                  <div class="slider__owl-main__slide-items__item__content__btn btn--animated mt-4 mr-3">
                    <router-link to="">تعرف علي المزيد</router-link>
                  </div>
                </div>
              </div>
            </div>
            <div class="slider__owl-dots">
              <div @click="displaySlider('last')" class="slider__owl-dots__next"><i class="fas fa-angle-right"></i></div>
              <div class="slider__owl-dots__slide">
                <div @click="displaySlider('slider_1')" :class="{active: slider_1}" class="slider__owl-dots__slide__item"></div>
                <div @click="displaySlider('slider_2')" :class="{active: slider_2}" class="slider__owl-dots__slide__item"></div>
                <div @click="displaySlider('slider_3')" :class="{active: slider_3}" class="slider__owl-dots__slide__item"></div>
              </div>
              <div @click="displaySlider('next')"  class="slider__owl-dots__previous"><i class="fas fa-angle-left"></i></div>
            </div>
          </div>

        </div>

        <div class="section section-bg who-we-are">
          <div class="section__container">
            <div class="section__title">نحن خبراء تطوير البرمجيات</div>
            <div class="section__title-2">نبني شراكات طويلة الأمد</div>
            <div class="section__desc">الذين يستفيدون من التكنولوجيا ويطرحون الأسئلة الصحيحة ليقدموا لك نتائج ملموسة في شكل برامج موثوقة وعالية الجودة ومستدامة يتم تسليمها في الوقت المحدد وفي المرة الأولى الصحيحة.</div>
            <div class="who-we-are__service row">
              <div class="who-we-are__service__left col-12 col-md-5">
                <img src="../assets/img/2.png">
              </div>
              <div class="who-we-are__service__center col-12 col-md-2">
                <span>ابدأ بتنفيذ مشروعك الآن</span>
                <router-link to="/contact-us" class="section__btn">ابدأ الآن</router-link>
              </div>
              <div class="who-we-are__service__right col-12 col-md-5">
                <img src="../assets/img/1.png">
              </div>
            </div>

          </div>
        </div>

        <div class="section service">
          <div class="service__container ">
            <div class="section__title">خدماتنا</div>
            <div class="section__desc">السبب الوحيد لعملنا هو جعل الحياة أقل صعوبة لعملائنا.</div>
            <div class="service__categories">
              <div class="service__categories__category">
                <div class="service__categories__category__items">
                  <div class="service__categories__category__items__item">
                    <div class="service__categories__category__items__item__content">
                      <div class="service__categories__category__items__item__front">
                        <i class="fas fa-laptop"></i>
                        <span class="break"></span>
                        <span class="service__categories__category__items__item__title">الخدمات البرمجيه</span>
                      </div>
                      <div class="service__categories__category__items__item__back">
                        <span class="service__categories__category__items__item__title">الخدمات البرمجيه</span>
                        <span class="service__categories__category__items__item__desc"> نعمل على تطوير موقعك الالكتروني وتطبيقات الهاتف بأحترافيه مما يناسب احتياجاتك واحتياجات عملائك للوصول للنجاح الذي تطمح له</span>
                      </div>
                    </div>
                  </div>
                  <div class="service__categories__category__items__item">
                    <div class="service__categories__category__items__item__content">
                      <div class="service__categories__category__items__item__front">
                        <i class="fas fa-laptop"></i>
                        <span class="break"></span>
                        <span class="service__categories__category__items__item__title">الخدمات التسويقيه</span>
                      </div>
                      <div class="service__categories__category__items__item__back">
                        <span class="service__categories__category__items__item__title">الخدمات التسويقيه</span>
                        <span class="service__categories__category__items__item__desc">نقدم لك الخطط الاستراتيجية والاستشارات والتي ن دورها تساعدك على الوصول الي اهدافك التسويقية بشكل زمني واضح</span>
                      </div>
                    </div>
                  </div>
                  <div class="service__categories__category__items__item">
                    <div class="service__categories__category__items__item__content">
                      <div class="service__categories__category__items__item__front">
                        <i class="fas fa-laptop"></i>
                        <span class="break"></span>
                        <span class="service__categories__category__items__item__title">الجرافيك</span>
                      </div>
                      <div class="service__categories__category__items__item__back">
                        <span class="service__categories__category__items__item__title">الجرافيك</span>
                        <span class="service__categories__category__items__item__desc">نركز على انشاء تصاميم جرافيك مميزه من الناحيه الجماله تجذب الانظار وتوصل هدف علامتك التجاريه الى الجمهور المستهدف</span>
                      </div>
                    </div>
                  </div>
                  <div class="service__categories__category__items__item">
                    <div class="service__categories__category__items__item__content">
                      <div class="service__categories__category__items__item__front">
                        <i class="fas fa-laptop"></i>
                        <span class="break"></span>
                        <span class="service__categories__category__items__item__title">الديكور</span>
                      </div>
                      <div class="service__categories__category__items__item__back">
                        <span class="service__categories__category__items__item__title">الديكور</span>
                        <span class="service__categories__category__items__item__desc">منذ يوم التأسيس قمنا بتصميم وتنفيذ المئات من الفلل والمنتجعات والفنادق والمراكز التجارية وابتكار العديد من التصاميم بوظائف ومفاهيم مختلفة من خلال اتباع اتجاهات التصميم المتجددة باستمرار حول العالم</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <router-link to="/services" class="btn btn-bg">تعرف علي المزيد</router-link>
          </div>
        </div>

        <div class="section section-bg blogs">
          <div class="section__container">
            <div class="section__title">قيمة العميل والثقة</div>
            <div class="blogs__items row">
              <div class="col-12 col-md-4">
                <div class="blogs__items__item">
                  <div class="blogs__items__item__name">
                    <b-avatar size="3rem"></b-avatar>
                    <span>Mohamed Emad</span>
                  </div>
                  <div class="blogs__items__item__content">التطبيق رائع جدا والتعامل يستمر باذن الله في المشاريع القادمه..كل الشكر لفريق العمل </div>
                </div>
              </div>
              <div class="col-12 col-md-4">
                <div class="blogs__items__item">
                  <div class="blogs__items__item__name">
                    <b-avatar size="3rem"></b-avatar>
                    <span>Mohamed Emad</span>
                  </div>
                  <div class="blogs__items__item__content">موقع إلكتروني رائع ودعم فني ممتاز وبرنامج إداري موفر للوقت بشكل كبير</div>
                </div>
              </div>
              <div class="col-12 col-md-4">
                <div class="blogs__items__item">
                  <div class="blogs__items__item__name">
                    <b-avatar size="3rem"></b-avatar>
                    <span>Mohamed Emad</span>
                  </div>
                  <div class="blogs__items__item__content">ليس شكرا ولا مجامله ولكن كل فريق العمل الموجود بالشركه يعد من اكثر الفرق المتعاونه في هذا المجال  و دائما يتطلع الي الافضل</div>
                </div>
              </div>
            </div>
            <router-link to="/blogs" class="section__btn mt-5">اكتشف المزيد من الاراء</router-link>
          </div>
        </div>

        <div class="section clients">
          <div class="section__container">
            <div class="section__title">عملائنا</div>
            <spinner v-if="clients_is_loading"></spinner>

            <div v-if="!is_loading && clients.length > 0" class="clients__items row">
              <div v-for="client in clients" :key="client.id"
                   class="clients__items__item col-6 col-md-2">
                <img :src="client.photo">
              </div>
            </div>
            <router-link class="section__btn section__btn-bg" to="/clients">تعرف علي المزيد</router-link>
          </div>
        </div>

        <div class="row">
          <div class="col-12 col-md-6">
            <div class="section">
              <div class="section__container sub-section">
                <div class="sub-section__title">لنصنع معًا</div>
                <div class="sub-section__title-2">أخبرنا عن مشاكلك وسنبحث عن الحل الأفضل!</div>
                <router-link to="/contact-us" class="sub-section__btn">لنتحدث</router-link>
              </div>
            </div>
          </div>
          <div class="col-12 col-md-6">
            <div class="section section-bg">
              <div class="section__container sub-section">
                <div class="sub-section__title">نحن نوظف</div>
                <div class="sub-section__title-2">أرسل لنا سيرتك الذاتية وكن عضوًا في نحن نعمل!</div>
                <router-link to="/join-us" class="sub-section__btn sub-section__btn-2">قدم الآن</router-link>
              </div>
            </div>

          </div>
        </div>

        <div class="section work-with">
          <div class="section__container">
            <div class="section__title">العمل مع تقنيات المستقبل</div>
            <div class="row work-with__technologies">
              <div class="col-6 col-md-3 work-with__technologies__item">
                <img src="../assets/img/technologies/vuejs.svg">
              </div>
              <div class="col-6 col-md-3 work-with__technologies__item">
                <img src="../assets/img/technologies/aws.svg">
              </div>
              <div class="col-6 col-md-3 work-with__technologies__item">
                <img src="../assets/img/technologies/golang.svg">
              </div>
              <div class="col-6 col-md-3 work-with__technologies__item">
                <img src="../assets/img/technologies/laravel.svg">
              </div>
              <div class="col-6 col-md-3 work-with__technologies__item">
                <img src="../assets/img/technologies/nodejs.svg">
              </div>
              <div class="col-6 col-md-3 work-with__technologies__item">
                <img src="../assets/img/technologies/react.svg">
              </div>
              <div class="col-6 col-md-3 work-with__technologies__item">
                <img src="../assets/img/technologies/typescript.svg">
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="en" v-if="getLang === 'en'">
        <div class="slider">
          <div class="slider__owl-main">
            <div class="slider__owl-main__slide-items">
              <div v-if="slider_1" class="slider__owl-main__slide-items__item animate__animated animate__fadeIn">
                <div class="slider__owl-main__slide-items__item__overlay"></div>
                <img class="slider__owl-main__slide-items__item__bg" :src="sliders[0].image_en">
                <div class="slider__owl-main__slide-items__item__content">
                  <div class="slider__owl-main__slide-items__item__content__main-title">
                    {{ sliders[0].title_en }}
                  </div>
                  <div class="slider__owl-main__slide-items__item__content__sub-title">
                    {{ sliders[0].description_en }}
                  </div>
                  <div class="slider__owl-main__slide-items__item__content__btn btn--animated mt-4 mr-3">
                    <router-link to="">Learn more</router-link>
                  </div>
                </div>
              </div>
              <div v-if="slider_2" class="slider__owl-main__slide-items__item animate__animated animate__fadeIn">
                <div class="slider__owl-main__slide-items__item__overlay"></div>
                <img class="slider__owl-main__slide-items__item__bg" :src="sliders[1].image_en">
                <div class="slider__owl-main__slide-items__item__content">
                  <div class="slider__owl-main__slide-items__item__content__main-title">
                    {{ sliders[1].title_en }}
                  </div>
                  <div class="slider__owl-main__slide-items__item__content__sub-title">
                    {{ sliders[1].description_en }}
                  </div>
                  <div class="slider__owl-main__slide-items__item__content__btn btn--animated mt-4 mr-3">
                    <router-link to="">Learn more</router-link>
                  </div>
                </div>
              </div>
              <div v-if="slider_3" class="slider__owl-main__slide-items__item animate__animated animate__fadeIn">
                <div class="slider__owl-main__slide-items__item__overlay"></div>
                <img class="slider__owl-main__slide-items__item__bg" :src="sliders[2].image_en">
                <div class="slider__owl-main__slide-items__item__content">
                  <div class="slider__owl-main__slide-items__item__content__main-title">
                    {{ sliders[2].title_en }}
                  </div>
                  <div class="slider__owl-main__slide-items__item__content__sub-title">
                    {{ sliders[2].description_en }}
                  </div>
                  <div class="slider__owl-main__slide-items__item__content__btn btn--animated mt-4 mr-3">
                    <router-link to="">Learn more</router-link>
                  </div>
                </div>
              </div>
            </div>

            <div class="slider__owl-dots">
              <div @click="displaySlider('last')"  class="slider__owl-dots__previous"><i class="fas fa-angle-left"></i></div>
              <div class="slider__owl-dots__slide">
                <div @click="displaySlider('slider_1')" :class="{active: slider_1}" class="slider__owl-dots__slide__item"></div>
                <div @click="displaySlider('slider_2')" :class="{active: slider_2}" class="slider__owl-dots__slide__item"></div>
                <div @click="displaySlider('slider_3')" :class="{active: slider_3}" class="slider__owl-dots__slide__item"></div>
              </div>
              <div  @click="displaySlider('next')" class="slider__owl-dots__next"><i class="fas fa-angle-right"></i></div>
            </div>
          </div>
        </div>

        <div class="section section-bg who-we-are">
          <div class="section__container">
            <div class="section__title text-center">We are software development experts</div>
            <div class="section__title-2 text-center">We build long-lasting partnerships</div>
            <div class="section__desc text-center">who leverage technology and ask the right questions to bring you tangible results in the form of reliable, high quality, sustainable software that is delivered on time, right first time.</div>
            <div class="who-we-are__service row">
              <div class="who-we-are__service__left col-12 col-md-5">
                <img src="../assets/img/1.png">
              </div>
              <div class="who-we-are__service__center col-12 col-md-2">
                <span>Start your project now</span>
                <router-link class="section__btn" to="/contact-us">Start now</router-link>
              </div>
              <div class="who-we-are__service__right col-12 col-md-5">
                <img src="../assets/img/2.png">
              </div>
            </div>
          </div>
        </div>

        <div class="section service">
          <div class="service__container">
            <div class="section__title">Our services</div>
            <div class="section__desc">The sole reason we are in business is to make life less difficult for our clients.</div>
            <div class="service__categories">
              <div class="service__categories__category">
                <div class="service__categories__category__items">
                  <div class="service__categories__category__items__item">
                    <div class="service__categories__category__items__item__content">
                      <div class="service__categories__category__items__item__front">
                        <i class="fas fa-laptop"></i>
                        <span class="break"></span>
                        <span class="service__categories__category__items__item__title">Software services</span>
                      </div>
                      <div class="service__categories__category__items__item__back">
                        <span class="service__categories__category__items__item__title">Software services</span>
                        <span class="service__categories__category__items__item__desc">
                          We are working on developing your website and mobile applications professionally to suit your needs and the needs of your customers to reach the success you aspire to
                        </span>
                      </div>
                    </div>
                  </div>
                  <div class="service__categories__category__items__item">
                    <div class="service__categories__category__items__item__content">
                      <div class="service__categories__category__items__item__front">
                        <i class="fas fa-laptop"></i>
                        <span class="break"></span>
                        <span class="service__categories__category__items__item__title">Marketing Services</span>
                      </div>
                      <div class="service__categories__category__items__item__back">
                        <span class="service__categories__category__items__item__title">Marketing Services</span>
                        <span class="service__categories__category__items__item__desc">
                          We provide you with strategic plans and consultations, whose role will help you reach your marketing goals in a clear time frame
                        </span>
                      </div>
                    </div>
                  </div>
                  <div class="service__categories__category__items__item">
                    <div class="service__categories__category__items__item__content">
                      <div class="service__categories__category__items__item__front">
                        <i class="fas fa-laptop"></i>
                        <span class="break"></span>
                        <span class="service__categories__category__items__item__title">Graphic</span>
                      </div>
                      <div class="service__categories__category__items__item__back">
                        <span class="service__categories__category__items__item__title">Graphic</span>
                        <span class="service__categories__category__items__item__desc">
                          We focus on creating distinctive graphic designs in terms of beauty that attract attention and reach the goal of your brand to the target audience.
                        </span>
                      </div>
                    </div>
                  </div>
                  <div class="service__categories__category__items__item">
                    <div class="service__categories__category__items__item__content">
                      <div class="service__categories__category__items__item__front">
                        <i class="fas fa-laptop"></i>
                        <span class="break"></span>
                        <span class="service__categories__category__items__item__title">Decoration</span>
                      </div>
                      <div class="service__categories__category__items__item__back">
                        <span class="service__categories__category__items__item__title">Decoration</span>
                        <span class="service__categories__category__items__item__desc">
                          Since the founding day, we have designed and implemented hundreds of villas, resorts, hotels and commercial centers and created many designs with different functions and concepts by following the constantly renewed design trends around the world.
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <router-link to="/services" class="btn btn-bg">Read more</router-link>
          </div>
        </div>

        <div class="section section-bg blogs">
          <div class="section__container">
            <div class="section__title">Client Value & Trust</div>
            <div class="blogs__items row">
              <div class="col-12 col-md-4">
                <div class="blogs__items__item">
                  <div class="blogs__items__item__name">
                    <b-avatar size="3rem"></b-avatar>
                    <span>Mohamed Emad</span>
                  </div>
                  <div class="blogs__items__item__content">It is not a thank you nor a compliment, but all the work team in the company is one of the most cooperative teams in this field and always looks forward to the best</div>
                </div>
              </div>
              <div class="col-12 col-md-4">
                <div class="blogs__items__item">
                  <div class="blogs__items__item__name">
                    <b-avatar size="3rem"></b-avatar>
                    <span>Mohamed Emad</span>
                  </div>
                  <div class="blogs__items__item__content">Great website, excellent technical support and hugely time-saving management software</div>
                  </div>
                </div>
              <div class="col-12 col-md-4">
                <div class="blogs__items__item">
                  <div class="blogs__items__item__name">
                    <b-avatar size="3rem"></b-avatar>
                    <span>Mohamed Emad</span>
                  </div>
                  <div class="blogs__items__item__content">The application is very wonderful and the deal will continue, God willing, in the upcoming projects..All thanks to the work team</div>
                </div>
              </div>
            </div>
            <router-link to="/blogs" class="section__btn mt-5">Find out more reviews</router-link>
          </div>
        </div>

        <div class="section clients">
          <div class="section__container">
            <div class="section__title">Our clients</div>
            <spinner v-if="clients_is_loading"></spinner>

            <div v-if="!is_loading && clients.length > 0" class="clients__items row">
              <div v-for="client in clients" :key="client.id"
                   class="clients__items__item col-6 col-md-2">
                <img :src="client.photo">
              </div>
            </div>

            <router-link class="section__btn section__btn-bg" to="/clients">Discover more</router-link>
          </div>
        </div>

        <div class="row">
          <div class="col-12 col-md-6">
            <div class="section">
              <div class="section__container sub-section">
                <div class="sub-section__title">Let's craft together</div>
                <div class="sub-section__title-2">Tell us about your issues and we will brainstorm the best solution!</div>
                <router-link to="/contact-us" class="sub-section__btn">Let's talk</router-link>
              </div>
            </div>
          </div>
          <div class="col-12 col-md-6">
            <div class="section section-bg">
              <div class="section__container sub-section">
                <div class="sub-section__title">We are Hiring</div>
                <div class="sub-section__title-2"> Send us your resume and become a member of We Work!</div>
                <router-link to="/join-us" class="sub-section__btn sub-section__btn-2">Apply now</router-link>
              </div>
            </div>

          </div>
        </div>

        <div class="section work-with">
          <div class="section__container">
            <div class="section__title">Work with future-proof technologies</div>
            <div class="row work-with__technologies">
              <div class="col-6 col-md-3 work-with__technologies__item">
                <img src="../assets/img/technologies/vuejs.svg">
              </div>
              <div class="col-6 col-md-3 work-with__technologies__item">
                <img src="../assets/img/technologies/aws.svg">
              </div>
              <div class="col-6 col-md-3 work-with__technologies__item">
                <img src="../assets/img/technologies/golang.svg">
              </div>
              <div class="col-6 col-md-3 work-with__technologies__item">
                <img src="../assets/img/technologies/laravel.svg">
              </div>
              <div class="col-6 col-md-3 work-with__technologies__item">
                <img src="../assets/img/technologies/nodejs.svg">
              </div>
              <div class="col-6 col-md-3 work-with__technologies__item">
                <img src="../assets/img/technologies/react.svg">
              </div>
              <div class="col-6 col-md-3 work-with__technologies__item">
                <img src="../assets/img/technologies/typescript.svg">
              </div>
            </div>
          </div>
        </div>

      </div>

    </div>

    <Footer></Footer>
  </div>
</template>

<script>
import Navbar from "@/components/ui/Navbar";
import Footer from "@/components/ui/Footer";
import SocialMedia from "@/components/ui/SocialMedia";
import Spinner from "@/components/ui/Spinner";
export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: "Home",
  components: {
    Spinner,
    SocialMedia,
    Footer,
    Navbar,
  },
  data() {
    return {
      slider_1: true,
      slider_2: false,
      slider_3: false,
      sliders: '',
      is_loading: false,
      clients_is_loading: false,
      error: false,
      error_message_ar: '',
      clients: '',
    }
  },
  mounted: function () {
    window.setInterval(() => {
      this.displaySlider('next')
    }, 5000);
  },
  created() {
    window.scrollTo(0,0);
    this.loadClients();
    this.loadSliders();
  },
  computed: {
    getLang() {
      return this.$store.getters['main/getLang'];
    }
  },
  methods: {
    setLang(lang) {
      this.$store.dispatch('main/setLang', lang);
    },
    displaySlider(slider) {
      if (slider == 'slider_1') {
        this.slider_3 = false
        this.slider_2 = false
        this.slider_1 = true
      }
      else if (slider == 'slider_2') {
        this.slider_3 = false
        this.slider_1 = false
        this.slider_2 = true
      }
      else if (slider == 'slider_3') {
        this.slider_1 = false
        this.slider_2 = false
        this.slider_3 = true
      }
      else if (slider == 'next') {
        if (this.slider_1) {
          this.slider_3 = false
          this.slider_1 = false
          this.slider_2 = true
        }
        else if (this.slider_2) {
          this.slider_2 = false
          this.slider_1 = false
          this.slider_3 = true
        }
        else if (this.slider_3) {
          this.slider_2 = false
          this.slider_3 = false
          this.slider_1 = true
        }
      }
      else if (slider == 'last') {
        if (this.slider_1) {
          this.slider_1 = false
          this.slider_2 = false
          this.slider_3 = true
        }
        else if (this.slider_2) {
          this.slider_2 = false
          this.slider_3 = false
          this.slider_1 = true
        }
        else if (this.slider_3) {
          this.slider_3 = false
          this.slider_1 = false
          this.slider_2 = true
        }

      }
    },
    async loadClients() {
      this.clients_is_loading = true;

      let myHeaders = new Headers();

      let requestOptions = {
        method: 'GET',
        headers: myHeaders,
        redirect: 'follow'
      };

      await fetch("https://api.we-work.pro/api/user/get-6-clients", requestOptions)
          .then(response => response.json())
          .then(result => {

            if (!result.status) {
              this.error = true;
              this.error_message_ar = result.msg;
            } else {
              this.clients = result.data;
            }
          })
          .catch(error => {
            this.error = true;
            this.error_message_ar = error.message;
          });

      this.clients_is_loading = false;
    },
    async loadSliders() {
      this.is_loading = true;

      let myHeaders = new Headers();
      let token = this.$store.getters.token;
      myHeaders.append("Authorization", "Bearer " + token);

      let requestOptions = {
        method: 'GET',
        headers: myHeaders,
        redirect: 'follow'
      };

      await fetch("https://api.we-work.pro/api/user/get-sliders", requestOptions)
          .then(response => response.json())
          .then(result => {
            if (!result.status) {
              this.error = true;
              this.error_message_ar = result.msg;
            } else {
              this.sliders = result.data;
            }
          })
          .catch(error => {
            this.error = true;
            this.error_message_ar = error.message;
          });

      this.is_loading = false;
    },

  }
}
</script>

<style lang="scss" scoped>
@import "../assets/css/variables";
@import "../assets/css/typography";
@import "../assets/css/mixins";

.home_view {
}

.spinner {
  margin: 10rem 0;
}

.slider {
  height: 100vh;
  position: relative;
  z-index: 90;
  opacity: 1;

  &__owl-main {
    height: 100%;

    &__slide-items {
      height: 100%;

      &__item {
        height: 100%;
        overflow: hidden;
        background-image: linear-gradient(45deg, rgba(194, 233, 221, 0.5) 1%, rgba(104, 119, 132, 0.5) 100%), linear-gradient(-45deg, #494d71 0%, rgba(217, 230, 185, 0.5) 80%);

        &__bg {
          height: 100%;
          width: 100%;
          object-fit: cover;
          vertical-align: middle;
          border-style: none;
        }

        &__overlay {
          position: absolute;
          top: 0;
          right: 0;
          left: 0;
          bottom: 0;

          &:after {
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            height: 100%;
            content: "";
            background: -webkit-linear-gradient(90deg, rgba(127, 191, 65, 0) 0%, rgba(255, 255, 255, 0.9) 60%, rgba(255, 255, 255, 1) 100%);
            background: linear-gradient(90deg, rgba(127, 191, 65, 0) 0%, rgba(255, 255, 255, 0.9) 60%, rgba(255, 255, 255, 1) 100%);
            background-image: url(../assets/img/hero-after.png);
            background-size: cover;
            background-repeat: no-repeat;
          }
        }

        &__content {
          z-index: 4;
          opacity: 1 !important;
          height: 100%;
          width: 50%;
          position: absolute;
          top: 0;
          right: 0;
          padding: 0 12% 0 0;
          display: flex;
          flex-direction: column;
          justify-content: center;
          user-select: none;

          &__main-title {
            color: $color-primary-dark-1;
            font-size: 2.25rem;
            animation-name: moveInLeft;
            animation-duration: 1s;
            animation-timing-function: ease-out;
          }

          &__sub-title {
            text-align: justify;
            margin: 1.1rem 0;
            font-weight: 200 !important;
            padding: 0 15px;
            color: #656565;
            line-height: 1.8;
            font-size: 1rem;
            animation: moveInRight 1s ease-out;
            line-height: 1.8;
          }

          &__btn a {
            text-decoration: none;
            background: linear-gradient(90deg, $color-primary-light, $color-primary-dark-1);
            border: 0;
            padding: 0.7rem 2rem;
            border-radius: 100px !important;
            color: $color-white;
            font-size: 1rem;
            width: max-content;
            box-shadow: 0 .8rem 1.8rem rgba($color-black,.2);

            &:hover,
            &:active,
            &:focus {
              background: linear-gradient(90deg, $color-primary-dark-1, $color-primary-light);
              transform: translateY(-3px);
              box-shadow: 0 20px 30px rgba($color-black,.2);
            }

          }
        }
      }
    }

  }

  &__owl-dots {
    display: flex;
    position: absolute;
    bottom: 10%;
    right: 10%;
    z-index: 5;

    &__next,
    &__previous {
      display: flex;
      justify-content: center;
      align-items: center;
      margin: 0 1.8rem;
      width: 3rem;
      height: 3rem;
      background-color: $color-white;
      border-radius: 50%;
      cursor: pointer;

      &:hover {
        transform: scale(1.2);
      }

      i {
        font-size: 3rem;
        font-weight: 100;
        color: $color-primary-dark-1;
      }
    }

    &__slide {
      display: flex;
      justify-content: center;
      align-items: center;
      margin: auto 0;
      cursor: pointer;

      &__item {
        width: 4rem;
        height: .2rem;
        background-color: $color-primary-dark-1;

        &.active {
          height: .7rem;
          border-radius: .8rem;
        }
      }
    }
  }
}

.en {
  & .slider__owl-dots {
    left: 10%;
  }

  & .slider {
    &__owl-main {

      &__slide-items {

        &__item {

          &__overlay {

            &:after {
              background-image: url(../assets/img/hero-after-en.png);
            }
          }

          &__content {
            right: auto;
            left: 0;
            padding: 0 0 0 12%;
          }
        }
      }
    }

    &__owl-dots {
      right: 10%;
    }
  }
}

.btn--animated {
  animation: moveInBottom .5s ease-out .75s;
  animation-fill-mode: backwards;
}

.section {
  padding: 6rem 0;

  &__container {
    padding: 0 10%;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  &__title {
    font-size: 4rem;
    font-weight: 700;
    margin-bottom: 1.5rem;
  }

  &__title-2 {
    font-size: 3rem;
    font-weight: 700;
    margin-bottom: 2.5rem;
  }

  &__desc {
    width: 60%;
    text-align: center;
    font-size: 1rem;
    margin-bottom: 1.5rem;
    font-weight: 500 ;
  }

  &__btn {
    background-color: $color-white;
    color: $color-primary;
    border: 1px solid $color-white;
    text-decoration: none;
    padding: .6rem 1rem;
    border-radius: .5rem;
    font-size: 1rem;

    &:hover {
      background-color: $color-primary;
      color: $color-white;
      border: 1px solid $color-primary;
    }
  }

  &__btn-bg {
    background-color: $color-primary;
    color: $color-white;
    border: 1px solid $color-primary;

    &:hover {
      background-color: $color-white;
      color: $color-primary;
      border: 1px solid $color-primary;
    }
  }

}

.section-bg {
  background-color: #255d9a;
  color: $color-white;
}

.who-we-are {
  &__service {
    display: flex;
    justify-content: center;
    align-items: center;

    &__center {
      margin: 2rem 0;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      & span {
        text-align: center;
        font-size: 20px;
        font-weight: 500;
        margin-bottom: 20px;
      }

    }

    &__left,
    &__right {
      padding: 0 4.5%;

      & img {
        width: 100%;
      }
    }
  }

}

.service {
  margin-bottom: 20px;

  &__container {
    padding: 0 4%;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  &__categories {
    width: 100%;

    &__category {
      width: 100%;
      display: flex;
      margin: 1.6rem 0 3rem;

      &__items {
        width: 100%;
        padding: 0 1%;
        display: flex;
        flex-wrap: wrap;

        &__item {
          margin: 2rem 1% 2rem 0;
          position: relative;
          width: 24%;
          height: 20rem;
          border-radius: 1rem;
          perspective: 500rem;

          &__content {
            position: absolute;
            width: 100%;
            height: 100%;
            box-shadow: 0 0 1.1rem rgba(0,0,0,0.3);
            transition: transform 1s;
            transform-style: preserve-3d;
            border-radius: 15px;
          }

          &:hover .service__categories__category__items__item__content {
            transform: rotateY(180deg);
            transition: transform 0.5s;
          }

          &__front,
          &__back {
            position: absolute;
            height: 100%;
            width: 100%;
            backface-visibility: hidden;
            border-radius: 15px;
            padding: 15% 5% 10%;
            display: flex;
            flex-direction: column;
          }

          &__back {
            transform: rotateY(180deg);
            background-color: $color-primary;
            color: $color-white;
          }

          & i {
            font-size: 80px;
            color: $color-grey-dark-2;
            margin-bottom: 20px;
          }

          &__title {
            font-size: 1.6rem;
            font-weight: 600;
            margin: 1rem 0;
            color: $color-primary-dark-1;
          }

          &__desc {
            font-size: .8rem;
            margin: 10px;
            text-align: justify;
          }

          &__read-more {
            font-size: 18px;
            color: $color-primary-dark-1;
            text-decoration: none;
            margin-top: auto;

            & i {
              color: $color-primary-dark-1;
              font-size: 18px;
              margin: auto;
            }
          }
        }
      }
    }
  }
}

.service__categories__category__items__item__front .service__categories__category__items__item__title,
.service__categories__category__items__item__back .service__categories__category__items__item__read-more {
  margin-top: auto;
}

.service__categories__category__items__item__back .service__categories__category__items__item__title,
.service__categories__category__items__item__back .service__categories__category__items__item__read-more,
.service__categories__category__items__item__back .service__categories__category__items__item__read-more i {
  color: $color-white;
}

.service__categories__category__items__item__back .service__categories__category__items__item__title {
  margin-top: 0;
}

.blogs {

  &__items {
    width: 100%;

    &__item {
      box-shadow: 0 0 15px rgba(0,0,0,0.3);
      padding: 1.5rem .5rem;
      margin: .5rem;
      border-radius: 10px;
      cursor: pointer;

      &:hover {
        transform: scale(1.1);
      }

      &__name {
        direction: ltr;
        margin-left: 10px;
        display: flex;
        align-items: center;

        & span {
          font-size: 1.6rem;
          font-weight: 700;
          margin-bottom: 10px;
        }
      }

      &__content {
        padding: 0 5% 0 7%;
        font-size: 1rem;
        font-weight: 500;
      }
    }
  }
}

.clients {

  &__items {
    width: 100%;
    display: flex;
    justify-content: center;

    &__item {
      display: flex;
      justify-content: center;
      align-items: center;

      & img {
        height: 150px;
        max-width: 100%;
      }
    }
  }

  &__btn {
    margin-top: 20px;
    background-color: $color-primary;
    color: $color-white;
    text-decoration: none;
    padding: 10px 18px;
    border-radius: 10px;

    &:hover {
      background-color: $color-white;
      color: $color-primary;
    }
  }
}

.work-with {

  &__technologies {
    display: flex;
    justify-content: center;
    width: 100%;
    margin: 1.6rem 0;

    &__item {
      display: flex;
      justify-content: center;
      align-items: center;
      margin: 3.5rem 0;
    }
  }
}


.sub-section {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: start;

  &__title {
    font-size: 3rem;
    font-weight: 700;
    margin-bottom: 1rem;
  }

  &__title-2 {
    padding: 0 1rem;
    font-size: 1.1rem;
    font-weight: 500;
  }

  &__btn {
    text-decoration: none;
    margin: 1.5rem;
    padding: .5rem 1rem;
    border-radius: 10px;
    font-size: 1rem;
    font-weight: 500;
    cursor: pointer;
    background-color: $color-primary-dark-1;
    color: $color-white;
    border: 1px solid $color-primary-dark-1;

    &:hover {
      background-color: $color-white;
      color: $color-primary-dark-1;
      border: 1px solid $color-primary-dark-1;
    }
  }

  &__btn-2 {
    background-color: $color-white;
    color: $color-primary-dark-1;
    border: 1px solid $color-white;

    &:hover {
      background-color: $color-primary-dark-1;
      color: $color-white;
      border: 1px solid $color-primary-dark-1;
    }

  }
}

@include respond(tab-port) {
  .slider__owl-main__slide-items__item__content {
    width: 100%;
  }

  .slider__owl-main__slide-items__item__bg {
    opacity: .3;
  }

  .slider__owl-dots {
    right: 5%;
    bottom: 15%;
  }

  .en {
    & .slider__owl-dots {
      left: 5%;
      bottom: 15%;
    }

    & .slider__owl-dots__next i,
    & .slider__owl-dots__previous i {
      padding: .8rem;
    }
  }

  .section {

    &__title {
      text-align: center;
      font-size: 2.5rem;
    }

    &__title-2 {
      text-align: center;
      font-size: 2rem;
    }

    &__desc {
      text-align: center;
      width: 90%;
      font-size: 1rem;
    }
  }

  .service__categories__category {
    display: flex;
    flex-direction: column;
    margin-bottom: 60px;

    &__title {
      width: 100%;
      margin-bottom: 20px;
    }

    &__items {
      display: flex;
      flex-direction: column;

      &__item {
        width: 100%;
      }
    }
  }

}
</style>