<template>
  <div>Home</div>

</template>

<script>
export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: "Main"
}
</script>

<style scoped>

</style>